// eslint-disable-next-line no-redeclare,no-unused-vars
function addCardOptions(card, context, xhr, cont) {
  var contextFunctions = function (type, target) {
    var card = target.parents('.card');
    var key = card.attr('key');
    var settings = fields.start[key];
    if (!settings) return;
    if (!settings.object) settings.object = key;

    if (type == 'tableFields') {
      if (!settings.fields)
        settings.fields =
          parameter[settings.object][
            'tableFields' + ucfirst(settings.objectSub)
          ];
      sorter({
        title: 'Spaltenmanager',
        list: settings.fields,
        fields: fields[settings.object],
        save: function (value) {
          ajax({
            post: true,
            object: 'setting',
            send: {object: 'start', key: key, option: 'fields', value: value},
            callback: function () {
              settings.fields = value;
              dashboard.update();
            },
          });
        },
      });
    } else if (type == 'edit') {
      var sort = settings.sort;
      var sort2 = '';
      if (sort && isString(sort) && sort.slice(0, 1) == '-') {
        sort = sort.slice(1);
        sort2 = '-';
      }
      var boxFields = {
        label: {label: 'Name', value: settings.label},
        sort: {
          label: 'Sortierung',
          field: 'select',
          values: settings.fields,
          labels: arrayValues(
            fields[settings.object],
            'label',
            settings.fields
          ),
          value: sort,
        },
        sort2: {
          label: 'Reihenfolge',
          field: 'radio',
          values: ['', '-'],
          labels: ['aufsteigend', 'absteigend'],
          value: sort2,
        },
        preview: {
          label: info.title.preview,
          field: 'checkbox',
          value: settings.preview,
          number: true,
        },
        notification: {
          label: 'Browserbenachrichtigung',
          field: 'checkbox',
          value: settings.notification,
          number: true,
        },
        input: {
          label: 'Direkteingabe',
          field: 'checkbox',
          value: settings.input,
          number: true,
        },
        upload: {
          label: 'Direktupload',
          field: 'checkbox',
          value: settings.upload,
          number: true,
        },
      };
      buildPromptPopup(boxFields, function (result) {
        ajax({
          post: true,
          object: 'setting',
          send: {
            object: 'start',
            option: 'json',
            key: key,
            json: true,
            value: result,
          },
          callback: function () {
            dashboard.update();
          },
        });
      });
    } else if (type == 'hide' || type == 'remove') {
      parameter.start.boxes = card
        .parent()
        .siblings()
        .map(function () {
          return $(this).children('.card').attr('key');
        })
        .get();
      card.remove();
      ajax({
        post: true,
        object: 'setting',
        send: {
          object: 'start',
          key: 'boxes',
          value: parameter.start.boxes,
        },
      });
      if (type == 'remove')
        ajax({
          post: true,
          object: 'setting',
          send: {object: 'start', key: key, value: ''},
        });
    }
  };

  contextMenu(card, context, contextFunctions);
}
